import Button from "@mui/material/Button";
import verifytick from "../../assets/verify-tick.png";
import share from "../../assets/share.png";
import whitelike from "../../assets/whitelike.png";
import "./TempleDetailsView.scss";
import GoogleMap from "../../components/map/GoogleMap";
import LeftAddressCard from "../../components/card/LeftBlock/LeftAddressCard";
import RightBlockCard from "../../components/card/RightBlock/RightBlock";
import TempleDetailsBanner from "../../components/banner/TempleDetailsBanner";
import TitleBlockCard from "../../components/card/TitleBlock/TitleBlock";
import { Box, Grid, Paper } from "@mui/material";
import TextArea from "../../components/textarea/Textarea";
import Typography from "@mui/material/Typography";
import ImageViewer from "../../components/imageviewer/ImageViewer";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import ShareLink from "../../components/sharelink/sharelink";
import { useEffect, useState } from "react";

function TempleDetailsView(props: any) {
  // const checkDetails = props.templeDetails.


  const [urlToShare,setUrlToShare]  = useState<string>('')
  useEffect(() => {
  
    const hostname = window.location.hostname === 'localhost' ? 'http://localhost:3000' : 'https://'+ window.location.hostname;
    setUrlToShare(`${hostname}/pagelink?templeNameKey=${props?.templeDetails?.nameKey}`)
 
  }, [props.templeDetails]);

  const templeProfilePic = props.templeProfilePic();
  return (
    <Box className="pageContainer templeDetailsPage">
      <Box className="templeDetailsBlock">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={2} lg={3} xl={3} className="pagehdLeftBlock">
              {/* <LeftAddressCard
                header="Current Address"
                address="198/3, 3 Cross 80 Feet Road, Next.."
                footerButton="Edit or add an address"
              /> */}
            </Grid>
            <Grid item xs={12} md={8} lg={6} xl={6}>
              <Box className="templeDetailsCenterBlock">
                <TempleDetailsBanner templeProfilePic={templeProfilePic} />
                <Box className="shareIcon">
                  <ShareLink urlToShare={urlToShare}></ShareLink>
                </Box>
                <Box className="ganpatiTempleDetails">
                  <Typography className="templeDetailsTitle">
                    {props.templePersonalInfo.name
                      ? props.templePersonalInfo.name
                      : ""}
                    <ImageViewer pictureUrl={verifytick} alt="" />
                  </Typography>

                  {/* <Box className="ratings">
                    <i className="bi bi-star-fill"></i>
                    <Typography>
                      <span>{props.googleData?.rating}</span>{props.googleData?.user_ratings_total}+ ratings
                    </Typography>
                  </Box> */}
                  {/* <div className="subTitle">
                    <i className="bi bi-star-fill"></i>
                    <p>
                      <span>4.9</span>(9k+ ratings)<span>18k</span>followers
                    </p>
                  </div> */}
                  <Box className="templeDetailsContent">
                    <Box className="aboutTemple">
                      <TextArea
                        title={
                          props.templePersonalInfo.descriptions
                            ? props.templePersonalInfo.descriptions
                            : ""
                        }
                        elevation={0}
                      ></TextArea>
                    </Box>
                    <Box className="addressTemple">
                      <Box>
                        <LocationOnOutlinedIcon sx={{ fontSize: 20, opacity: 0.5 }} />
                      </Box>
                      <Box>
                        <TextArea
                          title={
                            props.templePersonalInfo.address &&
                              props.templePersonalInfo.address.formatted_address
                              ? props.templePersonalInfo.address.formatted_address
                              : ""
                          }
                          elevation={0}
                        ></TextArea>
                      </Box>

                    </Box>
                    <Box className="openingPujaTime">
                      <Box className="openingTime">
                        <Box>
                          <AccessTimeIcon sx={{ fontSize: 20, opacity: 0.5 }} />
                        </Box>
                        <Box>
                          <TextArea title="Open - 8 AM to 12 PM" elevation={0}></TextArea>
                          <TextArea title="Close - 3 PM to 5 PM" elevation={0}></TextArea>
                        </Box>
                      </Box>
                      <Box className="pujaTime">
                        <Box><AccessTimeIcon sx={{ fontSize: 20, opacity: 0.5 }} /></Box>
                        <Box>
                          <TextArea title="Puja timings -" elevation={0}></TextArea>
                          <TextArea title="8:30 AM to 11:45 AM" elevation={0}></TextArea>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="phnTemple">
                      <Box><CallOutlinedIcon sx={{ fontSize: 20, opacity: 0.5 }} /></Box>
                      <Box>
                        <TextArea title={props.templePersonalInfo.phoneNumber} elevation={0}></TextArea>
                      </Box>
                    </Box>
                    <Box className="googleMap">
                      <GoogleMap personalInfo={props.templePersonalInfo} />
                    </Box>
                    {/* <Box className="likeSharebtn">
                      <Box className="likeBtn">
                        <ImageViewer pictureUrl={whitelike} alt="" />
                      </Box>
                      <Box className="shareBtn">
                        <ImageViewer pictureUrl={share} alt="" />
                      </Box>
                    </Box> */}
                  </Box>
                  {/* <Box className="bottomTitle">
                    <TitleBlockCard title="Offerings" />
                  </Box> */}
                  <Box className="templeDetailsButton">
                    <Button
                      className="bookYourPujaBtn"
                      onClick={() => props.goToBookPujaScreen()}>
                      Book Your Puja
                    </Button>
                    {/* <Button className="makeDonation">Make donation</Button> */}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={2} lg={3} xl={3} className="pagehdRightBlock">
              {/* <RightBlockCard /> */}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default TempleDetailsView;
