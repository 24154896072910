import React, { useState } from 'react';
import { Box, Grid ,InputAdornment,TextField} from "@mui/material";
import MainCategoriesCard from "../../components/card/MainCategories/MainCategoriesCard";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import SearchIcon from "@mui/icons-material/Search";
import pujaImg from "../../assets/puja.jpg";

import "./SearchView.scss";
import SearchCard from "../../components/card/searchCard/searchCard";
import TempleCard from "../../components/card/Temple/TempleCard";
import PujaListCard from "../../components/card/Puja/PujaListCard";
import { Search } from '@mui/icons-material';

interface Props {
  selectCategory: (item: string) => void;
}

function SearchView(props: any) {
// const SearchView: React.FC<Props> = (props: any) => {
  const mergedList = props.mergedList;

  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleClick = (index: number) => {
    setActiveIndex(index);
  };

  const handleCombinedClick = (item: string, index: number) => {
    props.selectCategory(item);
    handleClick(index);
  };


  console.log("Category--->",props.categoryDetails)
  const categorySelectedIndex = props.categoryDetails?.categoryId

  return (
    <Box className="pageContainer">
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          md={2}
          lg={3}
          xl={3}
          className="pagehdLeftBlock"
        ></Grid>
        <Grid item xs={12} md={8} lg={6} xl={6} className="pageCenterBlock">
          <Box sx={{ flexGrow: 1 }}>
            <Box className="searchArea">
              <TextField 
              onChange={(e:any)=>props.searchTempleAndPujaForUser(e.target.value)} 
              id="outlined-basic" 
              // label="Outlined" 
              variant="outlined" 
              placeholder="Search for temples and pujas."
              className="searchAreaTextField"
              sx={{
                "& fieldset": { border: 'none' },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search sx={{ color: '#000'}}/>
                  </InputAdornment>
                ),
              }}
              />
            </Box>

            <Box className="mainCategories searchMainCategories">
                <List className="searchCatgList">
                    {props.pujaCategoryList.map((item: any, id: any, index: number) => {
                      return (
                        <ListItem className={categorySelectedIndex === item.categoryId ? 'active' : ''} sx={{cursor:"pointer"}} onClick={() => handleCombinedClick(item, index)}>
                          <ListItemAvatar>
                            <Avatar className="imgCircle">
                              <img src={item.url}></img>
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            className="listTitle"
                            secondary={item.name}
                          />
                        </ListItem>
                      );
                    })}
                </List>
            </Box>

            <Box className="subCatgCardView">
              {props.isCategoryView ? (<Box className="subCatgCardList">


                {props.categoryDetails ? (
                  <List>
                    {props.categoryDetails.subCategory.map((item: any, id: any) => {
                      return (
                        <ListItem sx={{cursor:"pointer"}} onClick={() => props.slectSubCategory(item.subCategoryId)}>
                          <ListItemAvatar>
                            <Avatar className="imgRounded">
                              <img src={item.url}></img>
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText className="subCatgTitle">
                            {item.name}
                          </ListItemText>
                        </ListItem>
                      )
                    })}
                  </List>
                ) : null}
              </Box>) : (
                <Box className="searchCard">
                  {/* Uncomment below section to see Puja and temple Listings */}
                  {mergedList.map((item: any, id: any) => {
                    return (
                      <SearchCard
                        searchedItem={item}
                        onClickNavigateTemple={props.onClickNavigateTemple}
                        onClickNavigatePuja={props.onClickNavigatePuja}
                      />
                    );
                  })}
                </Box>
              )}</Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={2}
          lg={3}
          xl={3}
          className="pagehdRightBlock"
        ></Grid>
      </Grid>
    </Box>
  );
}

export default SearchView;
