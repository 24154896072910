import React, { useState, useEffect } from "react";
import { Fab, Button } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';

function BackToTop() {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.pageYOffset > 1000) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <>
            {isVisible ? (
                <Button
                    color="primary"
                    onClick={scrollToTop}
                    sx={{
                        position: "fixed",
                        top: "72px",
                        bottom: "auto",
                        right: "16px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        width: "fit-content",
                        zIndex: 1000,
                        backgroundColor: "#000",
                        padding: "6px 16px",
                        borderRadius: "20px",
                        color: "#fff",
                        '&:hover': {
                            backgroundColor: "#000",
                            color: "#fff",
                        },
                        '@media (max-width: 768px)': {
                            top: "86px",
                            fontSize: "14px",
                        },
                    }}
                    startIcon={<ArrowCircleUpIcon />}
                >
                    Back to top
                </Button>
            ) : null}
        </>
    );
}

export default BackToTop;
