/* eslint-disable @typescript-eslint/no-unused-vars */
import { UserService, TempleService, PanchangService,CommonService ,OrderService} from "td_server";
import {
  setVerifiedTempleList,
  setUnverifiedTempleList,
  setLoading,
  setPanchangData,
  setAllTempleList,
  setShaktipeeth,
  setSelectedTempleDetails,
  setBenefitsList,
  setDarshanList,
  setMergedList,
  setAllPujaList
} from "./../slices/templeSlice";
export const getTempleAddressOnCoordinatesAction =
  (coordinate: any) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const { latitude, longitude } = coordinate;
      const response: any = await UserService.getUserAddressOnGeoLocation(
        latitude,
        longitude
      );
      if (response?.results) {
        dispatch(setLoading(false));
        return Promise.resolve(response?.results[0]);
      } else {
        dispatch(setLoading(false));
        return Promise.reject("");
      }
    } catch (error: any) {
      //console.log('Temple LOCATION : ', error);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getTempleAddressOnPinCodeAction =
  (pinCode: any) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = ""; //await UserService.getAddressOnPinCode(pinCode);
      if (response) {
        dispatch(setLoading(false));
        return Promise.resolve(response);
      } else {
        dispatch(setLoading(false));
        return Promise.reject("");
      }
    } catch (error: any) {
      //console.log('Temple LOCATION : ', error);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const addNewTempleDetails =
  (formData: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const userInfo = getState().user.userInfo;

      const response: any = await TempleService.addNewTempleDetails(
        formData,
        userInfo
      );
      dispatch(setLoading(false));
      console.log("addNewTempleDetails response", JSON.stringify(response));
      return Promise.resolve(response);
    } catch (e) {
      //console.log('Temple ADD ERROR : ', e);
      dispatch(setLoading(false));
      return Promise.reject(e);
    }
  };
export const addNewShivlingSaktipethDetails =
  (formData: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const userInfo = getState().user.userInfo;

      const response: any = await TempleService.addShivlingSaktipeethTemple(
        formData,
        userInfo
      );
      dispatch(setLoading(false));
      console.log(
        "addNewShivlingSaktipethDetails response",
        JSON.stringify(response)
      );
      return Promise.resolve(response);
    } catch (e) {
      //console.log('Temple ADD ERROR : ', e);
      dispatch(setLoading(false));
      return Promise.reject(e);
    }
  };

export const verifyNewTempleDetails =
  (formData: any, orgId: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const userInfo = getState().user.userInfo;
      const response: any = await TempleService.approveExistingTempleDetails(
        formData,
        userInfo,
        orgId
      );
      dispatch(setLoading(false));
      console.log("verifyNewTempleDetails response", JSON.stringify(response));
      return Promise.resolve(response);
    } catch (e) {
      //console.log('Temple ADD ERROR : ', e);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };
export const getUnverifiedTempleList =
  (locationData: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.getUnverifiedTempleList();
      //console.log("response = ", response);
      dispatch(setUnverifiedTempleList(response));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      //console.log('Temple LIST ERROR : ', e);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getVerrifiedTempleList =
  (locationData: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.getVerifiedTempleList();
      //console.log("response = ", response);
      dispatch(setVerifiedTempleList(response?.organisation));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      //console.log('Temple LIST ERROR : ', e);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };
export const getAllTempleList =
  (locationData: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.getAllTempleList();
      // console.log("response = === getAllTempleList", response);
      dispatch(setAllTempleList(response));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      //console.log('Temple LIST ERROR : ', e);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getPanchangData =
  (requestInfo: any) => async (dispatch: any, getState: any) => {
    // //console.log('response  ERROR : ',  requestInfo);
    try {
      // console.log("In apan action")
      dispatch(setLoading(true));
      // console.log("Panchang",requestInfo)
      const response: any = await PanchangService.getPanchangData(requestInfo);
      //  console.log('response  Panchang : ', response);
      dispatch(setPanchangData(response));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      //console.log('getPanchangData  ERROR : ', e);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getAllShaktipeethAction =
  () => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.getAllShaktipeeth();
      // console.log("response = ", response);
      dispatch(setShaktipeeth(response));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      //console.log('getPanchangData  ERROR : ', e);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

  export const getShaktipeethAndShivlingAction =
  () => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.getAllShaktipeeth();
      // console.log("response = ", response);
      dispatch(setShaktipeeth(response));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      //console.log('getPanchangData  ERROR : ', e);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getTempleDetailsById =
  (templeId: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.getOrganisationDetailsById(templeId);
      // console.log("response = ", response);
      dispatch(setSelectedTempleDetails(response));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      // console.log('getPanchangData  ERROR : ', e);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

  export const getTempleDetailsByNameKey =
  (templeId: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.getOrganisationDetailsByNameKey(templeId);
      // console.log("response = ", response);
      dispatch(setSelectedTempleDetails(response));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      // console.log('getPanchangData  ERROR : ', e);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };
  export const getBenefitsList =
  () => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await CommonService.getAllBenefitList();
      // console.log("response = ", response);
      dispatch(setBenefitsList(response?.benefitsList));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      //console.log('getPanchangData  ERROR : ', e);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

  export const getDarshanList =
  () => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.getAllDarshanList();
      dispatch(setDarshanList(response));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      //console.log('getPanchangData  ERROR : ', e);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

  export const getTempleAndPujaList =
  () => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.getTempleAndPujaList();
      // console.log("TempleAnd pujaList",response)
      const {mergedArray,pujaArray,templeArray} = response
      if(mergedArray&&mergedArray.length>0){
        dispatch(setMergedList(mergedArray))
      }
      if(pujaArray&&pujaArray.length>0){
        dispatch(setAllPujaList(pujaArray))
      }
      if(templeArray&&templeArray.length>0){
        console.log("Temple resposne",templeArray)
        templeArray.sort((a: any, b: any) => b?.position - a?.position);
        dispatch(setAllTempleList(templeArray))
      }
      // dispatch(setDarshanList(response));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      //console.log('getPanchangData  ERROR : ', e);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

  export const getPriceInfoOfPujaAndPackage =
  (payload:any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await OrderService.getPriceDetailsOfPuja(payload);
      // console.log("TempleAnd pujaList",response)
      return Promise.resolve(response);
    } catch (e) {
      //console.log('getPanchangData  ERROR : ', e);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };


