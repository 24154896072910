import puja from "../assets/puja.jpg";
import pandit from "../assets/pandit.jpg";
import temple from "../assets/temple.jpg";
import donation from "../assets/donation.jpg";
import kalipuja from "../assets/navratra-puja.png";
import krishnapuja from "../assets/shivratri.png";
import temple1 from "../assets/pt1.jpg";
import temple2 from "../assets/pt2.jpg";
import temple3 from "../assets/pt3.jpg";
import temple4 from "../assets/pt4.jpg";
import temple5 from "../assets/pt5.jpg";
import temple6 from "../assets/pt6.jpg";
import recentpost1 from "../assets/recentpost1.jpg";
import recentpost2 from "../assets/recentpost2.jpg";
import recentpost3 from "../assets/recentpost3.jpg";
import recentpost4 from "../assets/recentpost4.jpg";
import postprofile from "../assets/user-img.png";
import profilepost1 from "../assets/postprofile.jpg";
import profilepost2 from "../assets/postprofile2.jpg";
import profilepost3 from "../assets/postprofile3.jpg";
import profilepost4 from "../assets/postprofile4.jpg";
import shaktipith from "../assets/shaktipeetha.jpg";
import shivling from "../assets/shivling.jpg";
import chardham from "../assets/chardham.jpg";
import whiteLogo from "../assets/svgloading.gif";

import newStatusOrder from "../assets/new.webp";
import orderConfirmed from "../assets/order-delivered.webp";
import orderInProgress from "../assets/order-progress.webp";
import orderPujaPerformed from "../assets/puja-performed.webp";
import orderCompleted from "../assets/completed.webp";
import orderCancelled from "../assets/cancelled.webp";
import orderReadyToShip from "../assets/ready-to-ship.webp";
import orderShipped from "../assets/shipped.webp";
import orderOutForDelivery from "../assets/out-for-delivery.webp";
import orderDelivered from "../assets/delivered.webp";

//horoscope
import capricornImg from "../assets/horoscopes/capricorn.png";
import taurusImg from "../assets/horoscopes/taurus.png";
import aquariusImg from "../assets/horoscopes/aquarius.png";
import piscesImg from "../assets/horoscopes/pisces.png";
import ariesImg from "../assets/horoscopes/aries.png";
import geminiImg from "../assets/horoscopes/gemini.png";
import cancerImg from "../assets/horoscopes/cancer.png";
import leoImg from "../assets/horoscopes/leo.png";
import virgoImg from "../assets/horoscopes/virgo.png";
import libraImg from "../assets/horoscopes/libra.png";
import scorpioImg from "../assets/horoscopes/scorpio.png";
import sagittariusImg from "../assets/horoscopes/sagittarius.png";

export const URLTEMPLEAPP = {
  DEV: "https://chatbotomkaraonline2.southindia.cloudapp.azure.com/",
  STAGE: "",
  PROD: "",
};

export const getBaseUrl = () => {
  return URLTEMPLEAPP.DEV;
};

//update store data accrodingly
export type Store = {
  AuthSlice: { isLoggedIn: boolean };
};

export const mainCategories = [
  {
    code: "PUJAS",
    cardTitle: "Pujas",
    img: puja,
    navigatePath: "/pujas",
  },
  // {
  //   code: "pandit",
  //   cardTitle: "Pandit",
  //   img: pandit,
  //   navigatePath: "/pandit-booking",
  // },
  {
    code: "TEMPLES",
    cardTitle: "Temple",
    img: temple,
    navigatePath: "/temples",
  },
  // TODO need to re activate before chadha active
  // {
  //   code: "CHADHAVA",
  //   cardTitle: "Chadhava",
  //   img: donation,
  //   navigatePath: "/chadhavaTemples",
  // },
  // {
  //   code: "donation",
  //   cardTitle: "Donation",
  //   img: donation,
  //   navigatePath: "/donation",
  // },
];

export const upcomingPujas = [
  {
    // templeName: "Tarapith Temple, West Bengal",
    // pujaName: "Koushiki Amavasya",
    // pujaDate: "14th September, 2023",
    img: kalipuja,
    navigatePath: "/checkout",
  },
  {
    // templeName: "Subramanya Temple, Bengaluru",
    // pujaName: "Skanda Sashti - S.P",
    // pujaDate: "21 st September, 2023",
    img: krishnapuja,
    navigatePath: "/checkout",
  },
  {
    // templeName: "Tarapith Temple, West Bengal",
    // pujaName: "Koushiki Amavasya",
    // pujaDate: "14th September, 2023",
    img: kalipuja,
    navigatePath: "/checkout",
  },
  {
    // templeName: "Subramanya Temple, Bengaluru",
    // pujaName: "Skanda Sashti - S.P",
    // pujaDate: "21 st September, 2023",
    img: krishnapuja,
    navigatePath: "/checkout",
  },
];

export const promotionalPujaLoading = [
  {
    id: "78qrc8fH1I6zzRkZ7lH0",
    dashBoardBg: {
      uri: whiteLogo,
      type: "image/jpeg",
    },
  },
  {
    id: "C0J3dqHrUV6JaiCkB39T",
    dashBoardBg: {
      type: "image/jpeg",
      uri: whiteLogo,
    },
  },
];

export const popularTemples = [
  {
    id: "1XQPx3NAyURQqJOUDq1Q",
    personalInfo: {
      media: {
        pictures: [
          {
            pictureUrl: whiteLogo,
            isPrimary: false,
          },
        ],
      },
      phoneNumber: "",
      descriptions: "",
      name: "",
    },
  },
  {
    id: "6bez7PoQVaePQvBgrLuM",
    personalInfo: {
      name: "",
      descriptions: "",
      media: {
        pictures: [
          {
            pictureUrl: whiteLogo,
            isPrimary: false,
          },
        ],
      },
    },
  },
];

export const posts = [
  {
    postProfileImage: profilepost1,
    postUserName: "Dakshineswar Kali Temple",
    postTime: "5 m",
    postDescription: "sit amet consectetur adipisicing elit quod provident.",
    postImage: recentpost1,
  },
  {
    postProfileImage: profilepost2,
    postUserName: "Birla Mandir",
    postTime: "30 m",
    postDescription: "sit amet consectetur adipisicing elit quod provident.",
    postImage: recentpost2,
  },
  {
    postProfileImage: profilepost3,
    postUserName: "Shri Siddhivinayak Ganapati Temple",
    postTime: "25 m",
    postDescription: "sit amet consectetur adipisicing elit quod provident.",
    postImage: recentpost3,
  },
  {
    postProfileImage: profilepost4,
    postUserName: "Dakshineswar Kali Temple",
    postTime: "2 m",
    postDescription: "sit amet consectetur adipisicing elit quod provident.",
    postImage: recentpost4,
  },
];

export const featuresToDispaly = [
  {
    name: "51 Shalti Pitha",
    img: shaktipith,
    url: "/saktipeeth",
  },
  {
    name: "12 Shivling",
    img: shivling,
    url: "/shivling",
  },
  {
    name: "Chardham",
    img: chardham,
    url: "/chardham",
  },
];

export const ORDER_FILTER_LIST = [
  "Today",
  "15 Days",
  "30 Days",
  "Finantial Year",
  "Custom Date Range",
];

export const UPCOMING_PUJA_CAROUSAL_RESPONSIVE = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const POPULAR_TEMPLE_CAUROSAL_RESPONSIVE = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const FEATURE_TO_DISPLAY_CAUROSAL_RESPONSIVE = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const TEMPLE_CARD_CAUROSAL_RESPONSIVE = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const VIRTUAL_PUJA_BOOKINGCAROUSAL_RESPONSIVE = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const CHADHAVA_ADDON_SCROLL_RESPONSIVE = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 612 },
    items: 5,
  },
  mobile: {
    breakpoint: { max: 612, min: 0 },
    items: 3,
  },
};

export const CAROUSEL_REMOVE_ARRAOW_ON_DEVICE = [
  "tablet",
  "mobile",
  // "desktop",
  // "superLargeDesktop",
];
export const MODIFICATION_TYPE_LIST: string[] = ["PUJA", "DELIVERY"];

export const PUJA_STATUS_LIST: string[] = [
  "CONFIRMED",
  "IN PROGRESS",
  "PUJA PERFORMED",
  "COMPLETED",
  "CANCELED",
];

export const DELIVERY_STATUS_LIST: string[] = [
  "READY TO SHIP",
  "SHIPPED",
  "OUT FOR DELIVERY",
  "DELIVERED",
];

export const APPROLES = {
  NORMALUSER: {
    roleId: "NORMALUSER",
  },
  PANDIT: { roleId: "PANDIT" },
  ORGANISATION: {
    roleId: "ORGANISATION",
  },
  DEPARTMENTADMIN: { roleId: "DEPARTMENTADMIN" },
  ADMIN: { roleId: "ADMIN" },
  SUPERADMIN: { roleId: "SUPERADMIN" },
};

export const ORDER_STATUS = ["NEW", "CONFIRMED", "SHIPPED", "COMPLETED"];

export const IN_APP_ANALYTICS_KEY = {
  LIVE_DARSHAN_PAGE: "live_darshan_list_view",
  LIVE_DARSHAN_TEMPLE_NAME: "live_darshan_temple_name",
  PAYMENT_VIEW_OPEN: "payment_view_opened",
  PAYMENT_FAILED_ERROR: "payment_failed_error",
  PAYMENT_SUCCESS: "payment_success",
  PAYMENT_PLACE_BUTTON_CLICK: "payment_place_order_button_clicked",
  PUJA_PLACE_CHECKOUT_VIEW_OPENED: "puja_checkout_view_opened",
  SPECIAL_PUJA_VIEW_OPEN: "special_puja_view_opened",
  DEEP_LINK: "deep_link",
  DASHBOARD_VIEW: "dashboard_view",
  PANCHANG_VIEWED: "panchang_details_page_view",
  TEMPLE_DETAILS: "temple_details_page_view",

  OTHERS: "OTHERS",
};

export const ANALYTICS_STATUS = {
  CALL_BACK_REQUESTED: "call_back_requested_by_user",
  DID_NOT_PICKED_UP: "user_did_not_picked_up ",
  USER_CONTACTED: "concated",
  USER_NOT_INTERESTED_IN_PUJA_BOOKING: "user_not_interested_in_puja_booking",
  SPECIAL_REQUEST_FROM_USER: "special_request_from_user",
  APP_IMPROVEMENT_OR_SUGGESTION: "app_improvement_or_suggestion",
  CALL_FORWARDED_TO_SUPERIOR: "call_forwarded_to_superior",
};

export const PUJA_ORDER_STATUS = {
  NEW: "NEW",
  ACCEPTED: "ACCEPTED",
  PERFORMED: "PERFORMED",
  READY_TO_SHIP: "READY_TO_SHIP",
  SHIPPED: "SHIPPED",
  OUT_FOR_DELIVERY: "OUT_FOR_DELIVERY",
};

export const getStatusImage = (status: any) => {
  const STATUS_TO_IMAGE: any = {
    NEW: newStatusOrder,
    CONFIRMED: orderConfirmed,
    "IN PROGRESS": orderInProgress,
    "PUJA PERFORMED": orderPujaPerformed,
    COMPLETED: orderCompleted,
    CANCELED: orderCancelled,
    "READY TO SHIP": orderReadyToShip,
    SHIPPED: orderShipped,
    "OUT FOR DELIVERY": orderOutForDelivery,
    DELIVERED: orderDelivered,
  };
  return STATUS_TO_IMAGE[status] || null;
};

export const mobileRoutes = {
  NoSelection: {
    displayName: "No Selection",
    navId: "",
  },
  // Splash: {
  //   displayName: 'Splash',
  //   navId: 'Splash',
  // },
  // Login: {
  //   displayName: 'Login',
  //   navId: 'Login',
  // },
  // PersonalInfo: {
  //   displayName: 'PersonalInfo',
  //   navId: 'PersonalInfo',
  // },
  // Profile: {
  //   displayName: 'Profile',
  //   navId: 'Profile',
  // },
  // Register: {
  //   displayName: 'Register',
  //   navId: 'Register',
  // },
  // Home: {
  //   displayName: 'Home',
  //   navId: 'Home',
  // },
  // TempleDetails: {
  //   displayName: 'Temple Details',
  //   navId: 'TempleDetails',
  //   searchKey: 'Temples',
  // },
  // AddTemple: {
  //   displayName: 'Add Temple',
  //   navId: 'AddTemple',
  // },
  // VerifyTemple: {
  //   displayName: 'Verify Temple',
  //   navId: 'VerifyTemple',
  // },
  // Dashboard: {
  //   displayName: 'Dashboard',
  //   navId: 'Dashboard',
  // },
  // Settings: {
  //   displayName: 'Settings',
  //   navId: 'Settings',
  // },
  // SearchTab: {
  //   displayName: 'Search',
  //   navId: 'SearchTab',
  // },
  // NotificationTab: {
  //   displayName: 'Notifications',
  //   navId: 'NotificationTab',
  // },
  // CartTab: {
  //   displayName: 'Cart',
  //   navId: 'CartTab',
  // },
  // DashboardTab: {
  //   displayName: 'DashboardTab',
  //   navId: 'DashboardTab',
  // },
  // Donation: {
  //   displayName: 'Donation',
  //   navId: 'AddDonation',
  //   searchKey: 'Donation',
  // },
  // PujaList: {
  //   displayName: 'Virtual Puja Booking',
  //   navId: 'PujaList',
  //   searchKey: 'Puja',
  // },
  // PujaCheckout: {
  //   displayName: 'Checkout',
  //   navId: 'PujaCheckout',
  // },
  // Payment: {
  //   displayName: 'Total Payment',
  //   navId: 'payment',
  // },
  // Address: {
  //   displayName: 'My Addresses',
  //   navId: 'address',
  // },
  // AddAddress: {
  //   displayName: 'Add New Address',
  //   navId: 'addAddress',
  // },
  // Notifications: {
  //   displayName: 'Notifications',
  //   navId: 'notifications',
  // },
  // CcAvenue: {
  //   displayName: 'Payment',
  //   navId: 'ccavenue',
  // },
  // MyOrder: {
  //   displayName: 'MyOrder',
  //   navId: 'MyOrder',
  // },
  // TrackOrder: {
  //   displayName: 'Track Orders',
  //   navId: 'TrackOrder',
  // },
  // Badrinath: {
  //   displayName: 'Badrinath',
  //   navId: 'badrinath',
  // },
  // AddSaktipeeth: {
  //   displayName: 'Add Saktipeeth',
  //   navId: 'AddSaktipeeth',
  // },
  // TempleDashboard: {
  //   displayName: 'Dashboard',
  //   navId: 'TempleDashboard',
  // },

  // Shaktipeeth: {
  //   displayName: 'Saktipeeth',
  //   navId: 'shaktipeeth',
  // },
  // FeedbackScreen: {
  //   displayName: 'Feedback',
  //   navId: 'FeedbackScreen',
  // },
  // HelpScreen: {
  //   displayName: 'Help',
  //   navId: 'HelpScreen',
  // },
  // WebViewScreen: {
  //   displayName: 'Help',
  //   navId: 'WebViewScreen',
  // },
  // AppSettingsScreen: {
  //   displayName: 'App Settings',
  //   navId: 'AppSettingsScreen',
  // },
  // UpdateOrderDetailsScreen: {
  //   displayName: 'Update Order Details',
  //   navId: 'UpdateOrderDetailsScreen',
  // },
  // VerifyShaktipeeth: {
  //   displayName: 'Verify Shaktipeeth',
  //   navId: 'VerifyShaktipeeth',
  // },
  PanchangDetailsScreen: {
    displayName: "Panchang Details",
    navId: "PanchangDetailsScreen",
  },
  LiveDarshanTab: {
    displayName: "Live Darshan",
    navId: "LiveDarshanTab",
  },
  // AddLiveDarshanScreen: {
  //   displayName: 'Add/Edit Live Darshan',
  //   navId: 'AddLiveDarshanScreen',
  // },
  // ChangeUserTypeScreen: {
  //   displayName: 'Change User Type',
  //   navId: 'ChangeUserTypeScreen',
  // },
  SpecialPujaBookingScreen: {
    displayName: "Vishesh Puja",
    navId: "SpecialPujaBookingScreen",
  },
  // AddSpecialPujaConfigScreen: {
  //   displayName: 'Configure Visesh Puja',
  //   navId: 'AddSpecialPujaConfigScreen',
  // },
  PujaDetailsScreen: {
    displayName: "Puja Details",
    navId: "PujaDetailsScreen",
  },
  // PujaStepsDetailsScreen: {
  //   displayName: 'Back to Puja Page',
  //   navId: 'PujaStepsDetailsScreen',
  // },
  SubCategoryPujaList: {
    displayName: "Puja Sub Category",
    navId: "SubCategoryPujaList",
    searchKey: "SubCategory",
  },
};

export const zodiacSign = [
  {
    position: 0,
    name: "Aries",
    key: "aries",
    img: ariesImg,
  },
  {
    position: 1,
    name: "Taurus",
    key: "taurus",
    img: taurusImg,
  },
  {
    position: 2,
    name: "Gemini",
    key: "gemini",
    img: geminiImg,
  },
  {
    position: 3,
    name: "Cancer",
    key: "cancer",
    img: cancerImg,
  },
  {
    position: 4,
    name: "Leo",
    key: "leo",
    img: leoImg,
  },
  {
    position: 5,
    name: "Virgo",
    key: "virgo",
    img: virgoImg,
  },
  {
    position: 6,
    name: "Libra",
    key: "libra",
    img: libraImg,
  },
  {
    position: 7,
    name: "Scorpio",
    key: "scorpio",
    img: scorpioImg,
  },
  {
    position: 8,
    name: "Sagittarius",
    key: "sagittarius",
    img: sagittariusImg,
  },
  {
    position: 9,
    name: "Capricorn",
    key: "capricorn",
    img: capricornImg,
  },
  {
    position: 10,
    name: "Aquarius",
    key: "aquarius",
    img: aquariusImg,
  },
  {
    position: 11,
    name: "Pisces",
    key: "pisces",
    img: piscesImg,
  },
];


export const dakshinaPandit = [
  21,31,51
]

export const orderType = {
  chadhava: "TEMPLECHADHAVA",
  puja: "TEMPLEPUJA"
}
 
export const PUJA_PACKAGE_SCROLL_RESPONSIVE = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
  },
}

