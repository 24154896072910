import { useEffect, useState } from "react";
import downarrow from "../../../assets/down-arrow.png";
import RoundedButton from "../../button/RoundedButton";
import {
  Box,
  Button,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Badge,
} from "@mui/material";
import TextArea from "../../textarea/Textarea";
import templeIcon from "../../../assets/temple-icon.png";
import locationIcon from "../../../assets/loc-icon.png";
import pujaNameIcon from "../../../assets/puja-name-icon.png";
import devoteeIcon from "../../../assets/devotee-icon.png";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

import "./PujaListCard.scss";

export default function PujaListCard(props: any) {
  //console.info('Ankit',props?.pujaData?.pujaDetails?.multiplePujaDetails?.length)
  const [isPujaOverviewSelected, setIsPujaOverviewSelected] = useState(true);
  const DevoteeCnt = props.devoteeCount ? Number(props.devoteeCount) : 0;
  let devoteeString = "";

  if (props?.pujaData?.pujaDetails?.multiplePujaDetails?.length) {
    const maxMembersPackage =
      props?.pujaData?.pujaDetails?.multiplePujaDetails?.reduce(
        (max: any, current: any) =>
          parseInt(current.maxMemberAllowed) > parseInt(max.maxMemberAllowed)
            ? current
            : max
      );
    devoteeString = `Upto ${maxMembersPackage?.maxMemberAllowed || 0} devotees`;
  } else if (DevoteeCnt > 1) {
    devoteeString = "Upto " + DevoteeCnt + " devotees";
  } else if (DevoteeCnt === 1) {
    devoteeString = "1 devotee only";
  } else {
    devoteeString = "No data for devotees allowed";
  }

  const [bnftText, setBnftText] = useState("");
  useEffect(() => {
    let benifitText = "";
    if (props?.benefitList?.length > 0) {
      props?.benefitList.map((item: any, index = 0) => {
        benifitText += `${index !== 0 ? "|" : ""}  ${item.name} `;
      });
      setBnftText(benifitText);
    }
  }, [props.benefitList]);

  const pujaAddress =
    (props.temple?.address?.street ||
      props.temple?.address?.city ||
      props.temple?.address?.area) +
    " , " +
    props.temple?.address?.state;

  return (
    <Box className="pujaAreaCard">
      <Box className="pujaImg">
        <img src={props.templeProfilePic} alt="" />
        <Badge className="devoteeBadge">
          <Box className="badgeIcon">
            <img src={devoteeIcon} alt="" />
          </Box>
          <Box className="badgeText">
            <Typography>{devoteeString}</Typography>
          </Box>
        </Badge>
      </Box>

      <Box className="card-body">
        <Box className="cardTitle">
          <Typography>{props.pujaName}</Typography>
          <Typography className="subTitle">{bnftText}</Typography>
        </Box>

        <Box className="pujaDescription">
          <List>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: "50px" }}>
                <Avatar alt="Temple Icon" className="squreShape temple">
                  <img src={templeIcon}></img>
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={props.temple.name} />
            </ListItem>
            {/* <ListItem>
              <ListItemAvatar sx={{ minWidth: "50px" }}>
                <Avatar
                  alt="Puja Icon"
                  className="squreShape pricetag"
                >
                  <img src={pujaNameIcon}></img>
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={props.pujaName} />
            </ListItem> */}
            <ListItem>
              <ListItemAvatar sx={{ minWidth: "50px" }}>
                <Avatar alt="Location Icon" className="squreShape location">
                  <img src={locationIcon}></img>
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={pujaAddress} />
            </ListItem>
          </List>
        </Box>

        {/* <Typography className="selectPujaOverview">
          Puja Overview
        </Typography>

        <Typography className="selectPujaDes">
          {props.description}
        </Typography> */}
        <Button
          variant="contained"
          endIcon={<KeyboardDoubleArrowRightIcon />}
          className="cardLastBtn"
          onClick={() => props.onClickAddPuja(props.pujaData)}
          disabled={props.isPujaDisable}
        >
          Participate Now
        </Button>
      </Box>
    </Box>
    // <Box className="pujaCardBox">
    //   <Box className="cardTopPart">
    //     <Box className="cardTitle">
    //       <Typography>{props.pujaName}</Typography>
    //     </Box>
    //     <Box className="downArrow">
    //       <img src={downarrow} alt="" />
    //     </Box>
    //   </Box>

    //   <Box className="pujaImg">
    //     <img src={props.templeProfilePic} alt="" />
    //   </Box>

    //   <Box className="card-body">
    //     <Typography className="selectItem">
    //       <ul>
    //         <li onClick={() => setIsPujaOverviewSelected(true)}>
    //           <a className={"" + (isPujaOverviewSelected ? "active" : "")}>
    //             Overview
    //           </a>
    //         </li>
    //         <li onClick={() => setIsPujaOverviewSelected(false)}>
    //           <a className={"" + (!isPujaOverviewSelected ? "active" : "")}>
    //             Benefits of Puja
    //           </a>
    //         </li>
    //       </ul>
    //     </Typography>

    //     <Box className="aboutPuja">
    //       {isPujaOverviewSelected ? (
    //         <TextArea title={props.description} elevation={0}></TextArea>
    //       ) : (
    //         <TextArea title={props.benefits} elevation={0}></TextArea>
    //       )}
    //     </Box>
    //     <Box style={{margin: '1.5rem 0 0 0', display: 'flex'}}>
    //       <Grid container spacing={2} style={{alignItems: 'center'}}>
    //         <Grid item xs={6} md={6} lg={6} xl={6} style={{fontWeight: 'bold', paddingTop: '0'}}>
    //           <TextArea
    //             title={"₹ " + props.acctualPrice}
    //             elevation={0}
    //           ></TextArea>
    //         </Grid>
    //         <Grid item xs={6} md={6} lg={6} xl={6} style={{textAlign: 'right', paddingTop: '0'}}>
    //           <RoundedButton style={{padding: '0'}}
    //             title="Participate Now"
    //             onClick={() => props.onClickAddPuja(props.pujaData)}
    //             width="150px"
    //             height="50px"
    //             backgroundColor="#d56939"
    //             borderRadius="20px"
    //           />
    //         </Grid>
    //       </Grid>
    //     </Box>
    //   </Box>
    // </Box>
  );
}
