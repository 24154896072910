import React, { useState } from "react";
import {
  Container,
  Typography,
  Link,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import "./PrivacyPolicyView.scss";

const PrivacyPolicyView = (props: any) => {
  const [activeItem, setActiveItem] = useState(null); // Tracks the active list item

  const handleItemClick = (id: any) => {
    setActiveItem(id); // Set the clicked item as active
  };
  const nativeViewCheck = props.parentViewCheck;
  console.log("Native view chaeck vval", props.parentViewCheck);
  return (
    <Box
      className={`${
        props.parentViewCheck ? "pageContainer" : "pageContainerNative"
      } privacyPolicy`}
    >
      {/* <Box className="pageContainer privacyPolicy"> */}
      <Container className="container padding-0">
        <div className="sectionHead text-center style-4 mb-40">
          <small className="title_small d-none"></small>
          <Typography className="mainTitle mb-30">
            Temple Dekho <span>Privacy</span> <span> Policy </span>
          </Typography>
        </div>
        <Grid
          container
          // direction={{ xs: 'row-reverse', md: 'row' }}
          justifyContent="space-between"
          spacing={4}
          className="privacyContentGrid"
        >
          <Grid item xs={12} md={9} lg={9}>
            <div className="privacyInfoDetails mb-40">
              <Typography variant="body1" className="mb-20">
                At Omkara Infotech Pvt. Ltd., creators of the "Temple Dekho
                App", safeguarding your privacy is paramount to us. This Privacy
                Policy outlines how we collect, utilize, and disclose your data
                when you engage with our platform, including our website{" "}
                <a href="https://templedekho.com">(https://templedekho.com)</a>{" "}
                and our mobile application, Temple Dekho ("App"). Together,
                these are referred to as the "Platform".
                <br></br>
                <br></br>
                Throughout this Privacy Policy, "we", "our", "us", or the
                "Company" refer to Omkara Infotech Pvt. Ltd. "You", "your", or
                "user" denote any individual or entity utilizing our Platform.
                We pledge not to utilize or distribute your information to any
                party unless expressly outlined in this Privacy Policy.
                <br></br>
                <br></br>
                This Privacy Policy constitutes an electronic record under the
                Information Technology Act, 2000 (as amended / re-enacted) and
                its rules, specifically Rule 3(1) of the Information Technology
                (Intermediaries Guidelines and Digital Media Ethics Code) Rules,
                2021. By accessing and using this Platform, you agree to adhere
                to the terms and conditions outlined in this Privacy Policy, as
                well as our Terms of Use and Temple Dekho Content Guidelines.
                Furthermore, you consent to our collection, use, and disclosure
                of your Personal Information (as defined below) as described
                herein. If you do not agree with these terms and conditions,
                please refrain from using this Platform.
                <br></br>
                <br></br>
                Ensuring the protection and security of your personal
                information is our utmost priority. We have implemented all
                necessary and reasonable measures to safeguard the
                confidentiality of user information and its transmission over
                the internet.
              </Typography>

              <Typography variant="body1" className="mb-20">
                By utilizing our Services and accessing the Platform, or by
                providing us with your information through any means, you are
                agreeing to the terms outlined in this Privacy Policy. Your use
                of our Services constitutes express consent to our practices
                regarding the collection, use, and disclosure of your Personal
                Information (as defined below) as described in this Privacy
                Policy. By continuing to use our Services and the Platform, you
                acknowledge and agree to abide by both this Privacy Policy and
                our Terms of Use. If you do not agree with these terms, please
                refrain from subscribing to our Services, using the Platform, or
                providing us with any of your information.
              </Typography>

              <Typography variant="body1">
                This Privacy Policy applies only to information collected by the
                Company through the Platform, as outlined in the "Collection of
                Information" section above. It does not cover any unsolicited
                information you provide through this Platform or by any other
                means. Such unsolicited information, including information
                posted in public areas of the Platform, is considered
                non-confidential, and the Company is free to use and disclose it
                without restriction.
                <br></br>
                <br></br>
                The Company also protects your Personal Information offline as
                specified in this Privacy Policy. Access to your Personal
                Information is limited to employees, agents, partners, and third
                parties who the Company reasonably believes need the information
                to provide Services to you. However, the Company is not
                responsible for the confidentiality, security, or distribution
                of your Personal Information by our partners and third parties
                outside the scope of our agreement with them.
              </Typography>

              <Box id="personal-info" className="pt-60">
                <Typography variant="body1">
                  <Typography variant="h5" className="mb-10 fw-bold">
                    We collect and process personal information provided by you
                    through the Platform, our partners, or any engagement with
                    us. This includes, but is not limited to:
                  </Typography>
                  <ul className="mb-0">
                    <li>
                      1. Information you voluntarily provide, such as your name,
                      phone number, gender, photograph, date of birth, time of
                      birth, and place of birth.
                    </li>
                    <li>
                      2. Data automatically captured by the Platform, including
                      your mobile phone operating system, domain name, and IP
                      address to help us identify your location. This
                      information reveals nothing personal beyond the IP address
                      from which you accessed the Platform.
                    </li>
                    <li>
                      3. Contacts list from your mobile device, accessed only
                      with your consent, and you have the option to deny this
                      access.
                    </li>
                  </ul>
                </Typography>
              </Box>

              <Box id="information-security" className="pt-60">
                <Typography variant="body1" className="mb-20">
                  <Typography variant="h5" className="mb-10 mt-10 fw-bold">
                    Information Security
                  </Typography>
                  Information used for logging in includes your name, phone
                  number or email, password or OTP, profile photo, and IP
                  address. During registration, we collect your phone number and
                  request access to SMS messages to send a One-Time-Password
                  ("OTP") for identity confirmation.
                </Typography>

                <Typography variant="body1" className="mb-20">
                  We do not store any payment or card-related information, such
                  as card number, account number, validity date, expiry date, or
                  CVV number when you make payments through the Platform.
                </Typography>

                <Typography variant="body1" className="">
                  In addition to Login Information, we also collect gender, date
                  of birth, address, and any biography provided in your user
                  profile.
                  <br></br>
                  <br></br>
                  During registration on our Platform, we collect your phone
                  number and request access to SMS messages on your device by
                  sending a One-Time-Password ("OTP"). You confirm your identity
                  by entering the OTP.
                </Typography>
              </Box>

              <Box id="user-shared-data-collected" className="pt-60">
                <Typography variant="body1" className="">
                  <Typography variant="h5" className="mb-10 mt-10 fw-bold">
                    This encompasses all information you choose to share with
                    other users on the Platform, such as:
                  </Typography>
                  <ul className="mb-0">
                    <li>
                      1. Any information that you voluntarily share about
                      yourself, such as quotes, images, political views,
                      religious beliefs, and more.
                    </li>
                    <li>
                      2. Your posts and comments on the Platform, which include
                      public profile details, photos, and videos captured with
                      your device's camera, as well as any reposts from other
                      users. This also includes associated Location Data and Log
                      Data.
                    </li>
                    <li>
                      3. Information about you shared by other users, including
                      communications directed to you, along with associated
                      Location Data and Log Data.
                    </li>
                    <li>
                      4. Information obtained from third-party sources,
                      including business partners, technical subcontractors,
                      analytics providers, etc. This data may be shared
                      internally and combined with information collected on our
                      Platform.
                    </li>
                  </ul>
                </Typography>
              </Box>

              <Box id="log-data" className="pt-60">
                <Typography variant="body1" className="mb-20">
                  <Typography variant="h5" className="mb-10 mt-10 fw-bold">
                    "Log Data" refers to information automatically collected
                    during your use of the Platform, which may include but is
                    not limited to:
                  </Typography>
                  <ul>
                    <li>
                      1. Technical details such as mobile carrier information,
                      configuration settings from your web browser or other
                      accessing programs, your IP address, and device version
                      and identification numbers.
                    </li>
                    <li>
                      2. Details of your activities on the Platform, including
                      search queries, visited social media profiles, utilized
                      mini applications, and specifics of other accessed or
                      requested information and content.
                    </li>
                    <li>
                      3. General information regarding your interactions on the
                      Platform, including the identity of users you communicate
                      with, and timestamps, dates, and durations of these
                      interactions.
                    </li>
                    <li>
                      4. Metadata associated with items you share through the
                      Platform, such as the date, time, or location of shared
                      photographs or videos.
                    </li>
                  </ul>
                </Typography>

                <Typography variant="body1" className="mb-20">
                  Our Platform utilizes cookies to distinguish you from other
                  users, enhancing your browsing experience and enabling us to
                  continually improve our services. We collect cookie data
                  stored on your device.
                </Typography>

                <Typography variant="body1" className="mb-20">
                  When you participate in events or surveys, we may request
                  certain Personal Information from you, which can identify you.
                  If we utilize a third-party service provider to conduct these
                  surveys, you will be informed prior to completing the survey.
                </Typography>

                <Typography variant="body1" className="mb-20">
                  We may access your mobile device's contact list, but only with
                  your explicit permission. You have the option to deny us
                  access to this information.
                </Typography>

                <Typography variant="body1" className="mb-20">
                  "Location Information" encompasses data obtained from your
                  GPS, IP address, and/or public posts containing location
                  details. You may share the following location information with
                  us and other Platform users when utilizing specific
                  location-based features, such as entering your Prasad Delivery
                  Address or any other features introduced on our Platform.
                  Additionally, we derive location information from your IP
                  address, device, or internet service when you access the
                  Platform, aiding in the prevention of multiple or fraudulent
                  log-ins to your account.
                </Typography>

                <Typography variant="body1" className="">
                  Any information you provide to our customer support team
                  regarding assistance or support you may need when using our
                  Platform.
                </Typography>
              </Box>

              <Box id="device-specific-data" className="pt-60">
                <Typography variant="body1" className="">
                  <Typography variant="h5" className="mb-10 mt-10 fw-bold">
                    "Device Data" encompasses, but is not limited to, the
                    following:
                  </Typography>
                  <ul className="mb-0">
                    <li>
                      1. Identifiers: Unique identifiers such as device IDs
                      obtained from games, apps, or accounts you use.
                    </li>
                    <li>
                      2. Device attributes: Information such as operating system
                      details, hardware and software versions, battery status,
                      signal strength, available storage space, browser type,
                      app names and types, and installed plugins.
                    </li>
                    <li>
                      3. Network and connections: Details such as the name of
                      your mobile operator or internet service provider (ISP),
                      language settings, time zone, phone number, IP address,
                      and connection speed.
                    </li>
                    <li>
                      4. Device operations: Information regarding actions and
                      behaviours performed on the device, such as whether an
                      application window is active or in the background.
                    </li>
                    <li>
                      5. Device signals: Collection of Bluetooth signals and
                      information about nearby Wi-Fi access points, beacons, and
                      cell towers.
                    </li>
                    <li>
                      6. Data from device settings: Information you authorize us
                      to access via device settings, such as GPS location,
                      camera usage, or access to photos.
                    </li>
                    <li>
                      7. Event Information: Any details you provide, such as
                      Family Members’ Names, Gotra, Wishes, etc., when
                      submitting entries for any events offered on the Platform
                      from time to time.
                    </li>
                    <li>
                      8. Media: We may access your mobile device's media
                      gallery, including but not limited to images and videos
                      stored on your phone. Rest assured, we always seek your
                      explicit permission before accessing your images, and you
                      have the option to decline such access.
                    </li>
                  </ul>
                </Typography>
              </Box>

              <Box id="service-provision-improvement" className="pt-60">
                <Typography variant="body1" className="">
                  <Typography variant="h5" className="mb-10 mt-10 fw-bold">
                    Service Provision and Improvement
                  </Typography>
                  <ul className="mb-0">
                    <li>1. To help provide you with the Services.</li>
                    <li>
                      2. To observe, improve, and administer the quality of
                      Service.
                    </li>
                    <li>
                      3. To analyze how the Platform is used and diagnose
                      technical problems.
                    </li>
                    <li>
                      4. To remember basic information provided by you for
                      effective access.
                    </li>
                    <li>5. To notify you about any changes to the Platform.</li>
                    <li>
                      6. To develop new features, improve existing features, and
                      incorporate user feedback and requests.
                    </li>
                    <li>
                      7. To understand how you use the Platform to enhance your
                      user experience.
                    </li>
                    <li>
                      8. To optimize your overall user experience on our
                      Platform.
                    </li>
                  </ul>
                </Typography>
              </Box>

              <Box id="identity-security" className="pt-60">
                <Typography variant="body1" className="">
                  <Typography variant="h5" className="mb-10 mt-10 fw-bold">
                    Identity and Security
                  </Typography>
                  <ul className="mb-0">
                    <li>
                      1. To confirm your identity and determine your eligibility
                      to use the Platform and avail the Services.
                    </li>
                    <li>
                      2. To verify your identity and maintain account security.
                    </li>
                    <li>
                      3. To enforce the Company’s Terms of Use and other
                      policies.
                    </li>
                    <li>
                      4. To protect our rights and the rights of other Platform
                      users.
                    </li>
                    <li>
                      5. To regulate the Platform and conduct business
                      operations such as troubleshooting, data analysis,
                      testing, research, security, fraud detection, account
                      management, and surveys.
                    </li>
                  </ul>
                </Typography>
              </Box>

              <Box id="communication-user-support" className="pt-60">
                <Typography variant="body1" className="">
                  <Typography variant="h5" className="mb-10 mt-10 fw-bold">
                    Communication and User Support
                  </Typography>
                  <ul className="mb-0">
                    <li>
                      1. To facilitate communication and provide user support.
                    </li>
                    <li>
                      2. For the purpose of sending administrative notices,
                      Service-related alerts, and similar communication.
                    </li>
                    <li>
                      3. To keep you informed about Platform changes, including
                      updates to this Privacy Policy.
                    </li>
                    <li>4. To handle support issues you may encounter.</li>
                  </ul>
                </Typography>
              </Box>

              <Box id="legal-regulatory-compliance" className="pt-60">
                <Typography variant="body1" className="">
                  <Typography variant="h5" className="mb-10 mt-10 fw-bold">
                    Legal and Regulatory Compliance
                  </Typography>
                  <ul className="mb-0">
                    <li>
                      1. To enable the Company to comply with its legal and
                      regulatory obligations.
                    </li>
                    <li>
                      2. In accordance with TRAI regulations, to inform you that
                      we may reach out to users registered on the National Do
                      Not Call (DND) registry through calls and SMS for
                      essential communications related to our services.
                    </li>
                  </ul>
                </Typography>
              </Box>

              <Box id="location-based-services" className="pt-60">
                <Typography variant="body1" className="">
                  <Typography variant="h5" className="mb-10 mt-10 fw-bold">
                    Personalization and Location-Based Services
                  </Typography>
                  <ul className="mb-0">
                    <li>
                      1. To connect you with other Platform users through
                      various features of the Platform.
                    </li>
                    <li>
                      2. To provide location-based services that you choose to
                      use.
                    </li>
                    <li>
                      3. To customize the Platform experience for your mobile
                      device.
                    </li>
                    <li>
                      4. To facilitate the sharing of Platform content through
                      applications installed on your mobile device.
                    </li>
                  </ul>
                </Typography>
              </Box>

              <Box id="data-analysis-market-research" className="pt-60">
                <Typography variant="body1" className="">
                  <Typography variant="h5" className="mb-10 mt-10 fw-bold">
                    Data Analysis and Market Research
                  </Typography>
                  <ul className="mb-0">
                    <li>
                      1. To do market research, troubleshooting, protection
                      against error, project planning, fraud, and other criminal
                      activity.
                    </li>
                    <li>
                      2. To pseudonymize and aggregate information, including
                      Personal Information, for user data analysis (e.g.,
                      region, phone model, operating system, language, and
                      system version) to better understand user behavior.
                    </li>
                    <li>
                      3. To pseudonymize and aggregate information, including
                      Personal Information, for collecting web and account
                      traffic statistics related to third-party services
                      accessed via the Platform.
                    </li>
                    <li>
                      4. To evaluate and improve the effectiveness of
                      advertising, marketing, and promotional activities.
                    </li>
                  </ul>
                </Typography>
              </Box>

              <Box id="account-management" className="pt-60">
                <Typography variant="body1" className="">
                  <Typography variant="h5" className="mb-10 mt-10 fw-bold">
                    Account Management
                  </Typography>
                  <ul className="mb-0">
                    <li>
                      1. Creating and managing your Platform user account.
                    </li>
                    <li>
                      2. Verifying your identity to enforce our terms,
                      conditions, and policies.
                    </li>
                    <li>
                      3. Facilitating your participation in events and
                      potentially awarding prizes, if applicable.
                    </li>
                  </ul>
                </Typography>
              </Box>

              <Box id="media-storage-management" className="pt-60">
                <Typography variant="body1" className="">
                  <Typography variant="h5" className="mb-10 mt-10 fw-bold">
                    Media and Storage Management
                  </Typography>
                  <ul className="mb-0">
                    <li>
                      1. Facilitating the sharing of media (videos and images)
                      through the Platform.
                    </li>
                    <li>
                      2. Checking if your device has sufficient storage for
                      downloading content from the Platform for sharing via
                      WhatsApp and/or Facebook.
                    </li>
                  </ul>
                </Typography>
              </Box>

              <Box id="event-participation" className="pt-60">
                <Typography variant="body1" className="mb-20">
                  <Typography variant="h5" className="mb-10 mt-10 fw-bold">
                    Event Participation
                  </Typography>
                  Enabling your participation in events and potentially awarding
                  prizes, if applicable.
                </Typography>

                <Typography variant="body1" className="mb-20">
                  When you use our Platform to share content, you have control
                  over who can view it. For instance, when you share content
                  from our Platform on Facebook, you can specify whether to
                  share it with a friend, a group of friends, or all your
                  friends. Similarly, when using WhatsApp or another mobile app
                  to share Platform content, you choose the recipients within
                  your community. We do not oversee nor are we responsible for
                  how such individuals (whom you choose to share content with
                  via sharing options like WhatsApp or Facebook available on our
                  Platform) utilize the information you share with them.
                </Typography>

                <Typography variant="body1" className="mb-20">
                  Public content, such as posts or comments on your user profile
                  or community pages, is accessible to all, including search
                  engines. Any information voluntarily disclosed for posting on
                  the Platform, including profile page details, can be viewed by
                  anyone. When you submit, post, or share content in a public
                  community on the Platform, it may be viewed and redistributed
                  by others.
                  <br></br>
                  <br></br>
                  You should carefully consider whom you share content with, as
                  individuals who can view your activity on our Platform may
                  choose to further distribute it both within and outside the
                  intended audience, including individuals not originally
                  targeted.
                </Typography>

                <Typography variant="body1" className="mb-20">
                  Communities on Temple Dekho can be Private or Public. In
                  Private Communities, members require approval from Admins or
                  Founders to join. Admins or Founders can change a Community's
                  security from Private to Public and vice versa.
                  <br></br>
                  <br></br>
                  When you share a post in the Community, users can download or
                  re-share it across platforms. Your comments and likes on
                  others' posts are visible to their viewers.
                  <br></br>
                  <br></br>
                  You can also set the Community to private, allowing only
                  approved followers to see your posts. Users can create and
                  share content about you, which may appear on various
                  platforms. We do not rent or sell your Personal Information
                  unless anonymized as per our Terms.
                </Typography>

                <Typography variant="body1" className="">
                  We may share your Personal Information with any entity within
                  our corporate group. The term "Group" refers to any entity
                  controlled by us, controlling us, or under common control with
                  us, whether directly or indirectly.
                </Typography>
              </Box>

              <Box
                id="shared-personal-data-with-third-parties"
                className="pt-60"
              >
                <Typography variant="body1" className="mb-20">
                  <Typography variant="h5" className="mb-10 mt-10 fw-bold">
                    We may share your information, including Personal
                    Information, with specific third parties under the following
                    conditions:
                  </Typography>
                  <ul>
                    <li>
                      1. Affiliates: This includes business partners, suppliers,
                      and subcontractors who assist in providing, understanding,
                      and enhancing the Service and their own services as
                      necessary for the performance of any contract we enter
                      with you.
                    </li>
                    <li>
                      2. Government and Law Enforcement: We may share your
                      personal data or information with government bodies or law
                      enforcement agencies if we have a good-faith belief that
                      such sharing is reasonably necessary to comply with legal
                      obligations or government requests, protect the rights,
                      property, or safety of the Company, our customers, or the
                      public, or to address issues related to public safety,
                      fraud, security, or technical matters. Additionally, we
                      may disclose your information, including Personal
                      Information, in the following circumstances:
                    </li>
                    <li className="mt-10">
                      1.Business Transfers: If the Company or a substantial
                      portion of its assets is acquired by a third party,
                      personal data held about customers will be among the
                      transferred assets. If we are involved in a merger,
                      acquisition, bankruptcy, reorganization, or sale of assets
                      where your information may be transferred or subject to a
                      different Privacy Policy, we will notify you in advance.
                      You can opt out of any such new policy by deleting your
                      account before the transfer occurs.
                    </li>
                    <li>
                      2.Enforcement of Terms and Agreements: Disclosure may be
                      necessary to enforce our Terms and/or any other
                      agreements.
                    </li>
                  </ul>
                </Typography>

                <Typography variant="body1" className="mb-20">
                  To safeguard the information we gather, we have instituted
                  suitable technical and security measures. If you have been
                  issued (or have chosen) a username and password to access the
                  Platform, it is your responsibility to keep them confidential.
                  Please refrain from sharing your password with anyone.
                </Typography>

                <Typography variant="body1" className="mb-20">
                  We store your personal information on servers provided by
                  Microsoft Azure, located at 15010 NE 36th Street, Microsoft
                  Campus Building 92, Redmond, WA 98052 and Google LLC, located
                  at (1101 S Flower St, Burbank, California 91502, USA, both in
                  India and internationally. Microsoft Azure and Google Cloud
                  Platform employ security measures to safeguard against the
                  loss, misuse, and alteration of information. Detailed
                  information about these security measures can be found at
                  their respective websites: https://azure.microsoft.com/en-in
                  and https://cloud.google.com. For further details, you can
                  review the privacy policies of Microsoft Azure and Google
                  Cloud Platform at
                  https://privacy.microsoft.com/en-us/privacystatement and
                  https://policies.google.com/privacy.
                </Typography>

                <Typography variant="body1" className="mb-20">
                  The Company may regularly update this Privacy Policy. Whenever
                  changes are made, we will post the updated Privacy Policy at
                  this link for your awareness. It is your responsibility to
                  check this page regularly to stay informed about any revisions
                  to this Privacy Policy.
                </Typography>

                <Typography variant="body1" className="mb-20">
                  Regrettably, internet information transmission is not entirely
                  secure. While we strive to protect your Personal Information,
                  we cannot guarantee the security of any data transmitted to
                  the Platform; transmission is at your own risk. We employ
                  stringent procedures and security features to prevent
                  unauthorized access once we receive your information.
                </Typography>

                <Typography variant="body1" className="mb-20">
                  You have the option to delete or remove content from your user
                  account/profile at any time. However, please note that we
                  retain access to the history of your activities and account on
                  our Platform. You can correct, update, supplement, or delete
                  Personal Information from your account by logging in and
                  visiting your profile page. Additionally, you can opt out of
                  receiving unwanted email communications from us by following
                  the instructions provided in the message.
                  <br></br>
                  <br></br>
                  Until your account is deleted, you will continue to receive
                  system emails. According to Section 5(6) of the Information
                  Technology (Reasonable Security Practices and Procedures and
                  Sensitive Personal Data or Information) Rules, 2011 ("Rules"),
                  you have the right to request a review, correction, or
                  amendment of the information we collect about you at any time.
                  <br></br>
                  <br></br>
                  Under Section 5(7) of the Rules, you also have the right to
                  withdraw your consent to the collection of your information
                  for future processing. However, please be aware that
                  withdrawing your consent may impact your ability to fully
                  utilize the Platform. For example, access to features such as
                  posting pictures from your mobile device may be restricted if
                  you do not grant us permission to access your media folder and
                  camera.
                  <br></br>
                  <br></br>
                  You may also request that we refrain from processing your
                  personal data for marketing purposes. You can exercise any of
                  these rights by contacting us at support@templedekho.com or on
                  our Customer Support Number. Please note that we require a
                  reasonable period of 30 (thirty) days to process your
                  requests.
                </Typography>

                <Typography variant="body1" className="mb-20">
                  We do not retain your Sensitive Personal Information (further
                  defined below in this paragraph) longer than necessary for
                  lawful purposes. While we will consider your request to delete
                  other content, copies of any public content may be retained
                  indefinitely in our systems, including cached and archived
                  pages of the Platform, or if other users have copied or saved
                  that information. Additionally, due to the nature of the
                  internet, copies of your content, even if removed or deleted
                  from your account, may persist elsewhere online indefinitely.
                  <br></br>
                  <br></br>
                  "Sensitive Personal Information" includes passwords and any
                  other information classified as sensitive under Section 3 of
                  the Rules.
                </Typography>

                <Typography variant="body1" className="mb-20">
                  Occasionally, the Platform may include links to websites of
                  partner networks, advertisers, affiliates, or other websites
                  and mobile applications. Please be aware that these websites
                  have their own privacy policies, and we do not assume any
                  responsibility or liability for these policies. Prior to
                  submitting any personal data to these websites or mobile
                  applications, please review their respective privacy policies.
                </Typography>

                <Typography variant="body1" className="mb-20">
                  Certain content displayed on the Platform is not hosted by us
                  but rather embedded from third-party sources. These "embeds"
                  include content hosted by external parties and integrated into
                  the Platform. For instance, YouTube videos or Giphy gifs that
                  appear within a Platform post are examples of such embeds.
                  When you access a Platform page containing an embedded YouTube
                  video, for instance, data about your activity is transmitted
                  to YouTube as if you were visiting their site directly.
                </Typography>

                <Typography variant="body1" className="mb-20">
                  Regarding privacy, third-party embeds on the Platform operate
                  independently from our control. We do not regulate the data
                  collection practices or subsequent use by third parties.
                  Therefore, these third-party embeds are governed solely by the
                  privacy policy of the respective third-party service provider
                  and are not covered by this Privacy Policy.
                </Typography>

                <Typography variant="body1" className="mb-20">
                  Certain embeds on the Platform may prompt you to provide
                  Personal Information, such as your email address, through a
                  form. While we strive to maintain a secure environment and
                  prevent misuse on the Platform, we cannot control how third
                  parties handle your information in such cases. As previously
                  noted, these actions fall outside the scope of this Privacy
                  Policy. Therefore, exercise caution when encountering embedded
                  forms on the Platform requesting your email address or other
                  Personal Information. Ensure you fully understand the
                  recipient and their intended use of your information before
                  submission. We advise against submitting Personal Information
                  through embedded forms to third parties.
                </Typography>

                <Typography variant="body1" className="mb-20">
                  <ul>
                    <li>
                      1. Cookies are small files that websites place on your
                      computer while you browse the web. Similar to many other
                      websites, we use cookies to understand how people use our
                      Platform and to enhance their functionality.
                    </li>
                    <li>
                      2. A pixel is a small piece of code embedded on a web page
                      or in an email notification. Like many websites, we use
                      pixels to track interactions with specific web or email
                      content. This helps us analyze and enhance our Platform
                      and personalize your experience.
                    </li>
                    <li>
                      3. Local storage is a standard technology that allows
                      websites or applications to store information locally on
                      your computer or mobile device. We utilize local storage
                      to tailor the content you see based on your past
                      interactions with our Platform.
                    </li>
                  </ul>
                </Typography>

                <Typography variant="body1" className="mb-20">
                  These technologies serve various purposes, including enhancing
                  your experience, ensuring security for both you and our users,
                  and providing efficient services on our Platform. They enable
                  easier navigation, faster access to features, and personalized
                  content. For example, they help us retain authentication
                  details to facilitate specific services, remember your
                  language preferences, and offer location-based services like
                  Tracking Your Shipment. Additionally, these technologies allow
                  us to gather data on your Platform usage, such as popular
                  pages and encountered errors, and aggregate visitor statistics
                  over time. It's important to note that while these cookies do
                  not personally identify you, they may collect personally
                  identifiable information like your name and profile picture
                  solely for functional purposes. We maintain transparency by
                  clearly outlining what information is collected, its intended
                  use, and any parties with whom it may be shared.
                </Typography>

                <Typography variant="body1" className="mb-20">
                  We utilize two types of cookies on our Platform: "session
                  cookies" and "persistent cookies." Session cookies are
                  temporary and remain on your device until you exit our
                  Platform, while persistent cookies persist on your device for
                  a longer period or until manually deleted, depending on the
                  cookie's lifetime and your app settings.
                  <br></br>
                  <br></br>
                  Additionally, certain pages may employ pixel tags (also known
                  as clear gifs) to gather information, which may be shared with
                  third parties supporting our promotional efforts and Platform
                  development. For instance, data on Platform visitors' usage
                  may be shared with our third-party advertising agency to
                  enhance the targeting of internet banner advertisements on the
                  Platform. This information, however, is not personally
                  identifiable, although it may be associated with your personal
                  information.
                </Typography>

                <Typography variant="body1" className="mb-20">
                  <ul className="mb-10">
                    <li>
                      1. Essential Cookies: These cookies are crucial for the
                      proper functioning of our Platform and enable you to
                      access its features, such as secure log-in authentication
                      and protection against fraudulent activities. Without
                      these cookies, the Platform cannot retain your actions
                      from previous visits within the same session, but they do
                      not identify you individually.
                    </li>
                    <li>
                      2. Analytical Cookies: These cookies allow us to analyze
                      how our Platform is used, helping us continuously enhance
                      its performance. They provide insights into visitor
                      interactions, including areas visited, time spent on
                      pages, and encountered issues like error messages. These
                      cookies collect data anonymously and do not identify you
                      personally.
                    </li>
                    <li>
                      3. Preference Cookies: Functionality cookies help the
                      Platform remember your preferences (such as language
                      settings and customization preferences), maintain
                      accessibility options, and indicate when you are logged
                      in. They are essential for delivering requested services
                      and enhancing your user experience on our Platform.
                    </li>
                  </ul>
                  If you choose not to accept these cookies, it could impact the
                  performance and functionality of the Platform, potentially
                  limiting access to its content. These cookies might gather
                  personally identifiable information that you have provided,
                  such as your name or profile picture. We are committed to
                  transparency regarding the data we collect, how it is
                  utilized, and with whom it is shared.
                </Typography>

                <Typography variant="body1" className="mb-20">
                  We partner with several suppliers who may set cookies on your
                  device while you visit our Platform, enabling these third
                  parties to provide their respective services.
                  <br></br>
                  <br></br>
                  Visiting our Platform may result in receiving cookies from
                  third-party websites or domains. We strive to identify these
                  cookies in advance so you can choose whether to accept them.
                  Further details about these cookies may be found on the
                  respective third party's website.
                </Typography>

                <Typography variant="body1" className="mb-20">
                  Initially, most internet browsers are configured to accept
                  cookies automatically. You have the option to modify these
                  settings to block cookies or to receive notifications when
                  cookies are sent to your device. There are various methods
                  available for managing cookies.
                  <br></br>
                  <br></br>
                  Disabling the cookies we utilize may affect your experience on
                  the Platform. For instance, you may be unable to access
                  certain parts of our Platform or receive personalized content.
                  <br></br>
                  <br></br>
                  If you access the Platform using different devices (such as
                  computers, smartphones, or tablets), you must adjust the
                  cookie preferences for each browser on each device
                  accordingly.
                </Typography>

                <Typography variant="body1" className="mb-20">
                  We regularly revise this Cookie Policy to align with updates
                  to our Platform and services. Should there be substantial
                  changes in how we gather, utilize, or disclose information
                  stored in cookies, we will publish these modifications in this
                  Cookie Policy and update the "Last Updated" date at the
                  beginning of the Privacy & Cookie Policy.
                </Typography>

                <Typography variant="body1" className="mb-20">
                  If you have previously disabled certain cookies, we may
                  continue to utilize information collected from those cookies
                  before your preference was disabled. However, we will cease
                  using the disabled cookie to gather any additional
                  information.
                </Typography>

                <Typography variant="body1" className="mb-20">
                  We may occasionally send you essential notifications related
                  to our services, such as when we temporarily suspend the
                  Platform for maintenance, or for security, privacy, or
                  administrative purposes. These notifications are typically
                  delivered via email or text message. Please note that you
                  cannot opt out of these essential announcements, as they are
                  not promotional and are intended solely to safeguard your
                  account and inform you about significant Platform updates.
                </Typography>

                <Typography variant="body1" className="mb-20">
                  The Company has implemented necessary security practices and
                  procedures to safeguard Personal Information. The Company
                  processes Personal Information in a manner compatible with the
                  purposes for which it was collected or authorized by the user.
                  Users have access to their Personal Information on the
                  Platform and can correct, amend, or delete inaccurate
                  information.
                  <br></br>
                  <br></br>
                  The Company employs commercially reasonable precautions to
                  protect your information against loss, theft, unauthorized
                  access, disclosure, reproduction, use, or amendment.
                  <br></br>
                  <br></br>
                  Information collected from you may be transferred to, stored,
                  and processed at any location within or outside India. By
                  submitting information on the Platform, you consent to this
                  transfer, storage, and processing. The Company will take
                  reasonable steps to ensure your information is treated
                  securely and in accordance with this Privacy Policy.
                  <br></br>
                  <br></br>
                  By using the Platform, you acknowledge the inherent security
                  risks of data transmission over the internet. Therefore, the
                  use of the Platform is at your own risk, and the Company is
                  not liable for any information disclosure due to transmission
                  errors, unauthorized third-party access, or other acts beyond
                  its reasonable control. You agree not to hold the Company
                  responsible for any security breaches.
                  <br></br>
                  <br></br>
                  If the Company becomes aware of a breach of your information’s
                  security, it will promptly notify you and take appropriate
                  action to remedy the breach to the best of its ability.
                </Typography>

                <Typography variant="body1" className="mb-20">
                  Your consent for the collection, use, and disclosure of your
                  Personal Information will remain valid until you withdraw it
                  in writing. If you withdraw your consent, the Company will
                  cease processing the relevant Personal Information, except
                  where it has other legal grounds for processing under
                  applicable laws. The Company will respond to your request
                  within a reasonable timeframe. You may withdraw your consent
                  at any time by contacting the Company at
                  support@templedekho.com.
                </Typography>

                <Typography variant="body1" className="mb-20">
                  You may request the deletion of your account at any time
                  through the Settings Privacy menus on the Platform. Upon
                  receiving your request, we will delete your account and
                  Personal Information unless retention is required by legal or
                  regulatory obligations. Please note that processing deletion
                  requests may take some time. Once the account is deleted, we
                  will not use your Personal Information for any processing,
                  except as required to comply with legal obligations in
                  accordance with this Policy.
                </Typography>

                <Typography variant="body1" className="mb-20">
                  You are responsible for maintaining the accuracy of the
                  information you provide, such as contact details during
                  account registration. The Company relies on users to provide
                  accurate and relevant information and to inform the Company of
                  any changes. If you wish to correct or update any of your
                  personal data held by us, you may submit your request via
                  email at support@templedekho.com.
                </Typography>

                <Typography variant="body1" className="mb-20">
                  The Company may periodically update this Privacy Policy to
                  reflect changes in its information practices. Any revisions
                  will take effect immediately upon posting the revised Privacy
                  Policy. In the event of any significant changes, the Company
                  will notify you through a notice on the Services before the
                  changes take effect. We encourage you to check this page
                  regularly for the latest updates on our privacy practices.
                </Typography>

                <Typography variant="body1" className="mb-20">
                  If you have any questions about this Privacy Policy or the
                  Company's information practices, you can reach out to us at
                  support@templedekho.com. We will make reasonable efforts to
                  promptly address any requests, questions, or concerns you may
                  have regarding the use of your Personal Information. Please
                  note that, except as required by law, we may not respond to
                  inquiries unrelated to this policy or our privacy practices.
                </Typography>
              </Box>

              <Typography variant="body1" className="mt-30 fw-bold">
                By reading this Privacy Policy, you acknowledge and agree to all
                the provisions stated herein.
              </Typography>

              <Typography variant="body1" className="mb-20">
                <Typography variant="body1" className="mt-10 fw-bold">
                  Omkara Infotech
                </Typography>
                Contact - +91 9980130928
                <br></br>
                Email -{" "}
                <a
                  href="mailto:info@omkarainfotech.com"
                  className="text-primary"
                >
                  info@omkarainfotech.com
                </a>
                <br></br>
                Address - 5th Floor Continental Chambers 15A, Hemanta Basu
                Sarani Kolkata - 700001
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Box className="rightPannelList">
              <Typography variant="h5">Table of contents</Typography>

              <List className="listOfPrivacyPolicy">
                <ListItem
                  component="a"
                  href="#personal-info"
                  className={activeItem === "personal-info" ? "active" : ""}
                  onClick={() => handleItemClick("personal-info")}
                >
                  <ListItemText primary="1. We collect personal data." />
                </ListItem>
                <ListItem
                  component="a"
                  href="#information-security"
                  className={
                    activeItem === "information-security" ? "active" : ""
                  }
                  onClick={() => handleItemClick("information-security")}
                >
                  <ListItemText primary="2. Information Security" />
                </ListItem>
                <ListItem
                  component="a"
                  href="#user-shared-data-collected"
                  className={
                    activeItem === "user-shared-data-collected" ? "active" : ""
                  }
                  onClick={() => handleItemClick("user-shared-data-collected")}
                >
                  <ListItemText primary="3. User-shared data collected" />
                </ListItem>
                <ListItem
                  component="a"
                  href="#log-data"
                  className={activeItem === "log-data" ? "active" : ""}
                  onClick={() => handleItemClick("log-data")}
                >
                  <ListItemText primary="4. Automatically collected platform log data" />
                </ListItem>
                <ListItem
                  component="a"
                  href="#device-specific-data"
                  className={
                    activeItem === "device-specific-data" ? "active" : ""
                  }
                  onClick={() => handleItemClick("device-specific-data")}
                >
                  <ListItemText primary="5. Collected device-specific data" />
                </ListItem>
                <ListItem
                  component="a"
                  href="#service-provision-improvement"
                  className={
                    activeItem === "service-provision-improvement"
                      ? "active"
                      : ""
                  }
                  onClick={() =>
                    handleItemClick("service-provision-improvement")
                  }
                >
                  <ListItemText primary="6. Service Provision and Improvement" />
                </ListItem>
                <ListItem
                  component="a"
                  href="#identity-security"
                  className={activeItem === "identity-security" ? "active" : ""}
                  onClick={() => handleItemClick("identity-security")}
                >
                  <ListItemText primary="7. Identity and Security" />
                </ListItem>
                <ListItem
                  component="a"
                  href="#communication-user-support"
                  className={
                    activeItem === "communication-user-support" ? "active" : ""
                  }
                  onClick={() => handleItemClick("communication-user-support")}
                >
                  <ListItemText primary="8. Communication and User Support" />
                </ListItem>
                <ListItem
                  component="a"
                  href="#legal-regulatory-compliance"
                  className={
                    activeItem === "legal-regulatory-compliance" ? "active" : ""
                  }
                  onClick={() => handleItemClick("legal-regulatory-compliance")}
                >
                  <ListItemText primary="9. Legal and Regulatory Compliance" />
                </ListItem>
                <ListItem
                  component="a"
                  href="#location-based-services"
                  className={
                    activeItem === "location-based-services" ? "active" : ""
                  }
                  onClick={() => handleItemClick("location-based-services")}
                >
                  <ListItemText primary="10. Personalization and Location-Based Services" />
                </ListItem>
                <ListItem
                  component="a"
                  href="#data-analysis-market-research"
                  className={
                    activeItem === "data-analysis-market-research"
                      ? "active"
                      : ""
                  }
                  onClick={() =>
                    handleItemClick("data-analysis-market-research")
                  }
                >
                  <ListItemText primary="11. Data Analysis and Market Research" />
                </ListItem>
                <ListItem
                  component="a"
                  href="#account-management"
                  className={
                    activeItem === "account-management" ? "active" : ""
                  }
                  onClick={() => handleItemClick("account-management")}
                >
                  <ListItemText primary="12. Account Management" />
                </ListItem>
                <ListItem
                  component="a"
                  href="#media-storage-management"
                  className={
                    activeItem === "media-storage-management" ? "active" : ""
                  }
                  onClick={() => handleItemClick("media-storage-management")}
                >
                  <ListItemText primary="13. Media and Storage Management" />
                </ListItem>
                <ListItem
                  component="a"
                  href="#event-participation"
                  className={
                    activeItem === "event-participation" ? "active" : ""
                  }
                  onClick={() => handleItemClick("event-participation")}
                >
                  <ListItemText primary="14. Event Participation" />
                </ListItem>
                <ListItem
                  component="a"
                  href="#shared-personal-data-with-third-parties"
                  className={
                    activeItem === "shared-personal-data-with-third-parties"
                      ? "active"
                      : ""
                  }
                  onClick={() =>
                    handleItemClick("shared-personal-data-with-third-parties")
                  }
                >
                  <ListItemText primary="15. Shared personal data with third parties under specific conditions" />
                </ListItem>
              </List>
            </Box>
          </Grid>
        </Grid>

        {/* <Typography variant="h5" className="mb-10 fw-bold">
                    PRIVACY POLICY CONTENT & USER AGREEMENT
                </Typography>
                <Typography variant="body1" className="mb-30 text">
                    By utilizing our Services and accessing the Platform, or by providing us with your information through any means, you are agreeing to the terms outlined in this Privacy Policy. Your use of our Services constitutes express consent to our practices regarding the collection, use, and disclosure of your Personal Information (as defined below) as described in this Privacy Policy. By continuing to use our Services and the Platform, you acknowledge and agree to abide by both this Privacy Policy and our Terms of Use. If you do not agree with these terms, please refrain from subscribing to our Services, using the Platform, or providing us with any of your information.
                </Typography>

                <Typography variant="h5" className="mb-10 fw-bold">
                    PRIVACY POLICY EXCLUSIONS
                </Typography>
                <Typography variant="body1" className="mb-30 text">
                    This Privacy Policy applies only to information collected by the Company through the Platform, as outlined in the "Collection of Information" section above. It does not cover any unsolicited information you provide through this Platform or by any other means. Such unsolicited information, including information posted in public areas of the Platform, is considered non-confidential, and the Company is free to use and disclose it without restriction.

                    The Company also protects your Personal Information offline as specified in this Privacy Policy. Access to your Personal Information is limited to employees, agents, partners, and third parties who the Company reasonably believes need the information to provide Services to you. However, the Company is not responsible for the confidentiality, security, or distribution of your Personal Information by our partners and third parties outside the scope of our agreement with them.
                </Typography>

                <Typography variant="h5" className="mb-10 fw-bold">
                    DATA COLLECTION & USAGE
                </Typography>
                <Typography variant="body1" className="mb-30 text">

                </Typography>



                <Typography variant="body1" className="mb-10 text">
                    If there are any questions or concerns regarding this privacy policy, you may contact our Grievance Officer
                    using the information below:
                </Typography>

                <Typography variant="body1" className="mb-30 text">
                    <b>Subham Sen</b> <br />
                    Omkara Infotech Pvt Ltd.: 5th Floor Continental Chambers 15A, Hemanta Basu Sarani Kolkata - 700001
                </Typography>

                <Typography variant="body1" className="mb-30 text">
                    <Link href="mailto:privacy@Omkarainfotech.com" className="text-primary">
                        privacy@Omkarainfotech.com
                    </Link>
                    <br />
                    We also have a privacy officer who may be contacted in relation to any privacy or data protection issues
                    through the above email address (privacy@omkarainfotech.com).
                </Typography>

                <Typography variant="body1" className="mb-30 text">
                    <b>Omkara Infotech</b> <br />
                    Contact - +91 9980130928 <br />
                    Email -{' '}
                    <Link href="mailto:info@omkarainfotech.com" className="text-primary">
                        info@omkarainfotech.com
                    </Link>
                    <br />
                    Address - 5th Floor Continental Chambers 15A, Hemanta Basu Sarani Kolkata - 700001
                </Typography> */}
      </Container>
    </Box>
  );
};

export default PrivacyPolicyView;
