import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Dialog from "@mui/material/Dialog";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// import { countries } from "../CountryPhoneCode/Countries";
import { Box, Button, TextField, Typography } from "@mui/material";
import "./CountryAutocomplete.scss";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook
import { useDispatch, useSelector } from "react-redux";
import { getActiveCountryList } from "../../store/actions/authAction";

function SimpleDialog(props: {
  onClose: any;
  selectedValue: string;
  open: any;
}) {
  const { onClose, selectedValue, open } = props;
  const [searchQuery, setSearchQuery] = useState("");
  const [countryCodeList, setCountryCodeList] = useState([]);
  const dispatch: any = useDispatch();
  const { countryCode } = useSelector((state: any) => state.auth);
  useEffect(() => {
    if (countryCode) {
      setCountryCodeList(countryCode);
    }
  }, [countryCode]);

  useEffect(() => {
    dispatch(getActiveCountryList());
  }, []);

  const pickImage = (country: any) => {
    onClose({
      flag: `https://flagcdn.com/w320/${country.code.toLowerCase()}.png`,
      dialCode: country.dial_code,
      code: country.code,
    });
  };
  const handleClose = (e: any) => {
    pickImage({
      code: "IN",
      dial_code: "91",
    });
  };

  const handleListItemClick = (country: any) => {
    pickImage(country);
  };

  const handleSearchChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearchQuery(event.target.value);
  };

  const filteredCountries = countryCodeList?.filter((country: any) =>
    country.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Dialog
      className="countryDilog"
      onClose={handleClose}
      open={open}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "16px",
          width: "550px",
          height: "650px",
          maxWidth: "100%",
        },
      }}
    >
      <Typography className="pickCodeText">
        Pick your <br /> Country Code
      </Typography>
      <Box className="searchContainer">
        <Box className="searchWrapper">
          <InputBase
            placeholder="Search here for your country."
            className="searchInput"
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <SearchIcon className="searchIcon" />
        </Box>
      </Box>

      <List className="countrylist">
        {filteredCountries.map((country: any) => (
          <ListItem disableGutters key={country.code}>
            <ListItemButton
              onClick={() => handleListItemClick(country)}
              className={`listItemButton ${
                selectedValue === country.code ? "listItemButtonSelected" : ""
              }`}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img
                  src={`https://flagcdn.com/w320/${country.code.toLowerCase()}.png`}
                  alt={country.name}
                  className="flagImage"
                />
                <ListItemText
                  primary={country.name}
                  primaryTypographyProps={{
                    style: { fontSize: "14px", fontWeight: "bold" },
                  }}
                />
              </Box>
              <Box className="dialCode">{country.dial_code}</Box>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

const CountryAutocomplete = ({ setIsOtpScreen, sendOtp, setPhone }: any) => {
  const [open, setOpen] = useState(false);
  const [selectedFlag, setSelectedFlag] = useState("https://flagcdn.com/w320/in.png");
  const { countryCode } = useSelector((state: any) => state.auth);
  const [selectedDialCode, setSelectedDialCode] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (countryCode && countryCode.length > 0) {
      console.log("Country list", countryCode);
      setSelectedDialCode(countryCode[0].dial_code);
      setSelectedCountry(countryCode[0].code);
      setSelectedFlag(
        `https://flagcdn.com/w320/${
          countryCode[0] && countryCode[0].code
            ? countryCode[0].code.toLowerCase()
            : "in"
        }.png`
      );
    }
  }, [countryCode]);
  useEffect(() => {
    setPhone(mobileNumber);
  }, [mobileNumber]);

  const handleClose = (value: {
    flag: string;
    dialCode: string;
    code: string;
  }) => {
    setOpen(false);

    if (typeof value !== "undefined") {
      console.log("Setting selected values", value);
      setSelectedFlag(value.flag);
      setSelectedDialCode(value.dialCode);
      setSelectedCountry(value.code);
    }
  };

  const handleProceedClick = (e: any) => {
    e.preventDefault();
    const phoneWithCode = `${mobileNumber}`;

    if (phoneWithCode?.trim().length > 0) {
      sendOtp();
      setIsOtpScreen(true);
    }
  };

  const handleGuestClick = () => {
    navigate("/");
  };

  return (
    <Box className="loginCard">
      <Typography variant="h1" className="newAccountTitle">
        Create a <br /> New Account
      </Typography>
      <Box className="line"></Box>
      <Box className="leftAlignText">
        <Typography variant="h6" className="subTitleText">
          Spiritual Experience with <br />
          <span className="boldText">Temple Dekho</span>
        </Typography>
        <Typography className="loginPhNoText">
          LOGIN/SIGNUP WITH YOUR MOBILE NUMBER
        </Typography>
      </Box>

      <Box className="countryInputArea">
        <button className="flagImgBtn" onClick={handleClickOpen}>
          <img src={selectedFlag} alt="Selected Country Flag" />
          <ArrowDropDownIcon className="dropdownIcon" />
        </button>

        <SimpleDialog
          selectedValue={selectedCountry}
          open={open}
          onClose={handleClose}
        />

        <TextField
          className="phNoInput"
          label="Mobile Number"
          fullWidth
          variant="standard"
          value={`${selectedDialCode || ""} ${mobileNumber || ""}`}
          onChange={(e) =>
            setMobileNumber(
              e.target.value.replace(selectedDialCode || "", "").trim()
            )
          }
        />
      </Box>

      <Box className="bottomButtonGroup">
        <Button
          className="proceedBtn"
          onClick={handleProceedClick}
          disabled={mobileNumber?.length === 0}
        >
          PROCEED
        </Button>

        <Button className="guestBtn" onClick={handleGuestClick}>
          CONTINUE AS A GUEST
        </Button>
      </Box>
    </Box>
  );
};

export default CountryAutocomplete;
